<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-tabs color="deep-purple accent-4">
            <v-tab>Pending</v-tab>
            <v-tab>Approved</v-tab>
            <v-tab>Damaged</v-tab>

            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card class="mt-10 mb-10" elevation="0">
                      <v-data-table
                        :headers="headerspending"
                        :items="pending_product"
                        :search="search"
                        class="elevation-1"
                        style="background: #f5f5f5"
                      >
                        <template v-slot:top>
                          <v-toolbar elevation="2" style="background: #f5f5f5">
                            <!-- <v-toolbar-title
                              style="margin-left: -14px"
                              class="mt-2 mb-2"
                              >All In</v-toolbar-title
                            > -->

                            <v-spacer></v-spacer>
                            <div>
                              <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                filled
                                rounded
                                dense
                                hide-details
                              ></v-text-field>
                            </div>
                            <v-divider class="mx-4" inset vertical></v-divider>
                          </v-toolbar>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-icon small class="mr-2" @click="view(item)">
                            mdi-eye
                          </v-icon>
                          <!-- <v-icon small @click="deleteItem(item)">
                            mdi-delete
                          </v-icon> -->
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card class="mt-10 mb-10" elevation="0">
                      <v-data-table
                        :headers="approve_headers"
                        :items="approved_product"
                        :search="search"
                        class="elevation-1"
                        style="background: #f5f5f5"
                      >
                        <template v-slot:top>
                          <v-toolbar elevation="2" style="background: #f5f5f5">
                            <!-- <v-toolbar-title
                              style="margin-left: -14px"
                              class="mt-2 mb-2"
                              >All In</v-toolbar-title
                            > -->

                            <v-spacer></v-spacer>
                            <div>
                              <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                filled
                                rounded
                                dense
                                hide-details
                              ></v-text-field>
                            </div>
                            <v-divider class="mx-4" inset vertical></v-divider>
                          </v-toolbar>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-icon small class="mr-2" @click="viewApprove(item)">
                            mdi-eye
                          </v-icon>
                          <!-- <v-icon small @click="deleteItem(item)">
                            mdi-delete
                          </v-icon> -->
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <!-- <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card class="mt-10 mb-10" elevation="0">
                      <v-data-table
                        :headers="headers"
                        :items="pending_product"
                        :search="search"
                        class="elevation-1"
                        style="background: #f5f5f5"
                      >
                        <template v-slot:top>
                          <v-toolbar elevation="2" style="background: #f5f5f5">
                            <v-toolbar-title
                              style="margin-left: -14px"
                              class="mt-2 mb-2"
                              >All In</v-toolbar-title
                            >

                            <v-spacer></v-spacer>
                            <div>
                              <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                filled
                                rounded
                                dense
                                hide-details
                              ></v-text-field>
                            </div>
                            <v-divider class="mx-4" inset vertical></v-divider>
                          </v-toolbar>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-icon small class="mr-2" @click="view(item)">
                            mdi-eye
                          </v-icon>

                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item> -->

            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card class="mt-10 mb-10" elevation="0">
                      <v-data-table
                        :headers="cancelled_headers"
                        :items="cancelled_product"
                        :search="search"
                        class="elevation-1"
                        style="background: #f5f5f5"
                      >
                        <template v-slot:top>
                          <v-toolbar elevation="2" style="background: #f5f5f5">
                            <!-- <v-toolbar-title
                              style="margin-left: -14px"
                              class="mt-2 mb-2"
                              >All In</v-toolbar-title
                            > -->

                            <v-spacer></v-spacer>
                            <div>
                              <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                filled
                                rounded
                                dense
                                hide-details
                              ></v-text-field>
                            </div>
                            <v-divider class="mx-4" inset vertical></v-divider>
                          </v-toolbar>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-icon small class="mr-2" @click="view(item)">
                            mdi-eye
                          </v-icon>
                          <!-- <v-icon small @click="deleteItem(item)">
                            mdi-delete
                          </v-icon> -->
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs>

          <!-- View Details Dialog  -->

          <v-dialog
            v-model="dialog"
            transition="scale-transition"
            origin="top right"
            :nudge-left="500"
            max-width="900px"
            max-height="650"
          >
            <v-card class="pa-5">
              <v-card class="pa-5" elevation="0">
                <v-app-bar absolute color="white" flat>
                  <v-toolbar-title class="font-weight-bold"
                    >Products Detail</v-toolbar-title
                  >

                  <v-spacer></v-spacer>
                  <v-btn
                    class=""
                    icon
                    elevation="0"
                    @click="closedialog"
                    color="red"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-app-bar>

                <v-card class="mx-auto mt-12" elevation="0">
                  <v-row
                    v-if="
                      child_details.product_images &&
                      child_details.product_images.length > 0
                    "
                  >
                    <v-carousel
                      cycle
                      height="400"
                      hide-delimiter-background
                      show-arrows-on-hover
                    >
                      <v-carousel-item
                        v-for="(slide, i) in child_details.product_images"
                        :key="i"
                        :src="slide.image_url"
                      >
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center"
                        >
                        </v-row>
                      </v-carousel-item>
                    </v-carousel>
                  </v-row>

                  <v-card-text>
                    <v-row align="center">
                      <v-col cols="4">
                        <p class="font-weight-medium">
                          Product ID :
                          <span class="font-weight-regular">{{
                            child_details.product_id
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="4">
                        <p class="font-weight-medium">
                          Specification ID :
                          <span class="font-weight-regular">{{
                            child_details.id
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="4">
                        <p class="font-weight-medium">
                          SKU :
                          <span class="font-weight-regular">{{
                            child_details.product_code.SKU
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="12"
                        ><p class="font-weight-medium">
                          Product Title :
                          <span class="font-weight-regular">{{
                            child_details.product_data.title
                          }}</span>
                        </p>
                      </v-col>

                      <v-col cols="12">
                        <p class="font-weight-medium">
                          Category :
                          <span class="font-weight-regular">{{
                            child_details.category_data.category
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Sub Category :
                          <span class="font-weight-regular">{{
                            child_details.category_data.sub_category
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Sub Sub Category :
                          <span class="font-weight-regular">{{
                            child_details.category_data.sub_sub_category
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Brand :
                          <span class="font-weight-regular"
                            >{{ child_details.product_data.brand }}
                          </span>
                        </p>
                      </v-col>

                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Warrenty :
                          <span class="font-weight-regular"
                            >{{ child_details.warranty }}
                            {{ child_details.warranty_unit }}</span
                          >
                        </p>
                      </v-col>

                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Unit :
                          <span class="font-weight-regular">{{
                            child_details.unit
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Origin :
                          <span class="font-weight-regular">{{
                            child_details.product_data.origin
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6"
                        ><p class="font-weight-medium">
                          Shipping Country :
                          <span class="font-weight-regular">{{
                            child_details.product_data.shipping_country
                          }}</span>
                        </p>
                      </v-col>

                      <v-col cols="12">
                        <p class="font-weight-medium">Product Description</p>
                        {{ child_details.product_data.description }}
                      </v-col>

                      <v-col cols="12">
                        <div>
                          <p class="font-weight-medium">Key Features :</p>

                          <li
                            class="mt-5"
                            v-for="(itemfeatures, index) in child_details
                              .product_data.key_features"
                            :key="index"
                          >
                            {{ itemfeatures }}
                          </li>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        Color :

                        {{ child_details.color }}
                      </v-col>
                      <v-col cols="6" class="pa-5">
                        Size :

                        {{ child_details.size }}
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card>
            </v-card>

            <!-- <v-card class="pa-5">
              <v-card-text class="pt-0" style="background: #f5f5f5">
                <v-card-title class="py-2 px-0 font-weight-bold"
                  >Child Site Shared Product Price</v-card-title
                >
                <v-divider></v-divider>
                <v-simple-table fixed-header>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">Quantity</th>
                        <th class="text-center">Status</th>
                        <th class="text-center">Purchase Price</th>
                      </tr>
                    </thead>
                    <tbody align="center">
                      <tr
                        v-for="(item, index) in child_details.max_min"
                        :key="index"
                      >
                        <td class="text-center">
                          {{ item.quantity }}
                        </td>
                        <td class="text-center">
                          {{ item.status }}
                        </td>
                        <td class="text-center">
                          {{ item.purchase_price }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card> -->

            <v-card class="pa-5">
              <v-card-text class="pt-0">
                <v-card-title class="py-2 px-0 font-weight-bold"
                  >Price Assign For Share Product</v-card-title
                >
                <!-- <v-divider></v-divider>
                <v-row>
                  <v-col cols="4">
                    <v-select
                          outlined
                          dense
                          :rules="[(v) => !!v || 'Brand Country is required']"
                          :items="country"
                          v-model="Brand_country"
                          label="Country Name"
                        ></v-select>
                  </v-col>
                  <v-col cols="4"> </v-col>
                  <v-col cols="4"> </v-col>
                </v-row> -->
                <v-divider></v-divider>
                <v-form ref="specPrice" v-model="valid_add_price_specification">
                  <v-simple-table fixed-header height="250px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Quantity</th>
                          <th class="text-left">Status</th>
                          <th class="text-center">Purchase Price</th>
                          <th class="text-center">Increament Type</th>
                          <th class="text-center">Amount</th>
                          <th class="text-center">Selling Price</th>
                          <!-- <th class="text-left">Action</th> -->
                        </tr>
                      </thead>
                      <tbody align="center">
                        <tr
                          v-for="(item, index) in child_details.child_max_min"
                          :key="index"
                        >
                          <!-- <td class="text-left">
                          <v-form>
                            <v-text-field
                              v-if="index"
                              outlined
                              type="number"
                              style="max-width: 100px; height: 50px"
                              class="mt-2"
                              flat
                              dense
                              v-model="item.quantity"
                            ></v-text-field>
                            <p v-else>1</p>
                          </v-form>
                        </td> -->
                          <td class="text-left">{{ item.quantity }}</td>
                          <td class="text-left">{{ item.status }}</td>
                          <td>{{ item.selling_price }}</td>

                          <td>
                            <v-select
                              outlined
                              dense
                              :items="percent_type"
                              v-model="item.increament_type"
                              style="max-width: 110px; height: 50px"
                            ></v-select>
                          </td>

                          <td>
                            <v-form>
                              <v-text-field
                                outlined
                                type="number"
                                style="max-width: 100px; height: 50px"
                                class="mt-2"
                                flat
                                dense
                                v-model="item.increament_value"
                              ></v-text-field>
                            </v-form>
                          </td>
                          <td>
                            <p v-if="item.increament_type == 'Percentage'">
                              {{
                                item.selling_price +
                                (item.selling_price * item.increament_value) /
                                  100
                              }}
                            </p>
                            <p v-else>
                              {{
                                Number(item.selling_price) +
                                Number(item.increament_value)
                              }}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-col cols="5" class="d-flex flex-row">
                    <p
                      v-if="
                        child_details.child_max_min &&
                        child_details.child_max_min.length > 0
                      "
                      class="mt-5 mr-2 font-weight-medium d-flex flex-row"
                    >
                      MRP : {{ child_details.child_max_min[0].mrp }}
                    </p>
                    <v-text-field
                      outlined
                      type="number"
                      style="max-width: 100px; height: 50px"
                      class="mt-2"
                      flat
                      dense
                      required
                      v-model="child_details.input_mrp"
                      label="New MRP"
                    ></v-text-field>
                    <!-- <v-text-field
                      outlined
                      type="number"
                      style="max-width: 150px; height: 50px"
                      class="mt-2"
                      flat
                      dense
                      v-model="mrp"
                    ></v-text-field> -->
                  </v-col>
                  <!-- <v-row>
                    <v-card>
                      <v-card-title>
                        For Delivery
                      </v-card-title>
                      <v-card-text>
                        <v-col>
                          <v-select
                          dense
                          v-model="child_details.delivery_product_unit"
                          :items="delivery_units"
                          label="Product Ubit Type"
                          :rules="[(v) => !!v || 'Unit is required']"
                          outlined

                          >

                          </v-select>
                        </v-col>
                      </v-card-text>
                    </v-card>
                  </v-row> -->

                  <v-row>
                    <v-card style="min-width: 100%; border: 1px solid black">
                      <v-card-title class="font-weight-medium pt-2"
                        >For Delivery</v-card-title
                      >
                      <v-card-text>
                        <v-row>
                          <v-col>
                            <v-checkbox
                              v-model="child_details.delivery_free"
                              label="Free Delivery ?"
                              color="red darken-3"
                              hide-details
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-radio-group
                              v-model="child_details.option"
                              row
                              @change="
                                deliveryOptionChanged(child_details.option)
                              "
                            >
                              <v-radio
                                label="All Over Bangladesh"
                                value="all"
                              ></v-radio>
                              <v-radio
                                label="Select Districts"
                                value="manual"
                              ></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                        <div v-show="child_details.option == 'manual'">
                          <v-row
                            v-for="(
                              delivery, index
                            ) in child_details.arrayForDelivery"
                            :key="index"
                          >
                            <v-col cols="3">
                              <v-select
                                :items="child_details.districts"
                                item-text="Area_name"
                                :menu-props="{ top: true, offsetY: true }"
                                :disabled="delivery.active"
                                label="Select District"
                                solo
                                v-model="delivery.selectedDistrict"
                                @change="onSelectDistrict(delivery, index)"
                                dense
                              ></v-select>
                            </v-col>
                            <v-col>
                              <v-select
                                v-model="delivery.selectedThana"
                                :items="delivery.thanas"
                                item-text="location_name"
                                :menu-props="{ top: true, offsetY: true }"
                                :disabled="delivery.active"
                                chips
                                dense
                                label="Select Thanas"
                                multiple
                                solo
                              >
                                <template v-slot:selection="{ item, index }">
                                  <v-chip v-if="index < 3">
                                    <span>{{ item.location_name }}</span>
                                  </v-chip>
                                  <span
                                    v-if="index == 3"
                                    class="grey--text caption"
                                  >
                                    (+{{ delivery.selectedThana.length - 3 }}
                                    others)
                                  </span>
                                </template>
                              </v-select>
                            </v-col>

                            <v-col cols="2">
                              <!-- index + 1 ==
                                      addItem.arrayForDelivery.length && -->
                              <v-row>
                                <v-btn
                                  fab
                                  small
                                  color="primary"
                                  :disabled="
                                    child_details.arrayForDelivery.length ==
                                    child_details.districts.length
                                  "
                                  @click="increaseDeliveryLength(index)"
                                >
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                                <v-btn
                                  fab
                                  small
                                  color="red"
                                  :disabled="
                                    child_details.arrayForDelivery.length == 1
                                  "
                                  @click="deleteDeliveryLength(index)"
                                >
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-row>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-row>
                  <v-card-actions>
                    <v-row>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="d-flex flex-row mt-2">
                        <v-btn
                          elevation="0"
                          color="red"
                          @click="CancelApprove(child_details)"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          @click="SaveApprove(child_details)"
                          elevation="0"
                          color="success"
                          :disabled="!valid_add_price_specification"
                        >
                          Approve
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
          <!--
          This dialog for view Approved Product details -->

          <v-dialog
            v-model="dialogapproved"
            transition="scale-transition"
            origin="top right"
            :nudge-left="500"
            max-width="900px"
            max-height="650"
          >
            <v-card class="pa-5">
              <v-card class="pa-5" elevation="0">
                <v-app-bar absolute color="white" flat>
                  <v-toolbar-title class="font-weight-bold"
                    >Products Detail</v-toolbar-title
                  >

                  <v-spacer></v-spacer>
                  <v-btn
                    class=""
                    icon
                    elevation="0"
                    @click="closedialog"
                    color="red"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-app-bar>

                <v-card class="mx-auto mt-12" elevation="0">
                  <v-row
                    v-if="
                      approvedchild_details.product_images &&
                      approvedchild_details.product_images.length > 0
                    "
                  >
                    <v-carousel
                      cycle
                      height="400"
                      hide-delimiter-background
                      show-arrows-on-hover
                    >
                      <v-carousel-item
                        v-for="(
                          slide, i
                        ) in approvedchild_details.product_images"
                        :key="i"
                        :src="slide.image_url"
                      >
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center"
                        >
                        </v-row>
                      </v-carousel-item>
                    </v-carousel>
                  </v-row>

                  <v-card-text>
                    <v-row align="center">
                      <v-col cols="4">
                        <p class="font-weight-medium">
                          Product ID :
                          <span class="font-weight-regular">{{
                            approvedchild_details.product_id
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="4">
                        <p class="font-weight-medium">
                          Specification ID :
                          <span class="font-weight-regular">{{
                            approvedchild_details.id
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="4">
                        <p class="font-weight-medium">
                          SKU :
                          <span class="font-weight-regular">{{
                            approvedchild_details.product_code.SKU
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="12"
                        ><p class="font-weight-medium">
                          Product Title :
                          <span class="font-weight-regular">{{
                            approvedchild_details.product_data.title
                          }}</span>
                        </p>
                      </v-col>

                      <v-col cols="12">
                        <p class="font-weight-medium">
                          Category :
                          <span class="font-weight-regular">{{
                            approvedchild_details.category_data.category
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Sub Category :
                          <span class="font-weight-regular">{{
                            approvedchild_details.category_data.sub_category
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Sub Sub Category :
                          <span class="font-weight-regular">{{
                            approvedchild_details.category_data.sub_sub_category
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Brand :
                          <span class="font-weight-regular"
                            >{{ approvedchild_details.product_data.brand }}
                          </span>
                        </p>
                      </v-col>

                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Warrenty :
                          <span class="font-weight-regular"
                            >{{ approvedchild_details.warranty }}
                            {{ approvedchild_details.warranty_unit }}</span
                          >
                        </p>
                      </v-col>

                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Unit :
                          <span class="font-weight-regular">{{
                            approvedchild_details.unit
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Origin :
                          <span class="font-weight-regular">{{
                            approvedchild_details.product_data.origin
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6"
                        ><p class="font-weight-medium">
                          Shipping Country :
                          <span class="font-weight-regular">{{
                            approvedchild_details.product_data.shipping_country
                          }}</span>
                        </p>
                      </v-col>

                      <v-col cols="12">
                        <p class="font-weight-medium">Product Description</p>
                        {{ approvedchild_details.product_data.description }}
                      </v-col>

                      <v-col cols="12">
                        <div>
                          <p class="font-weight-medium">Key Features :</p>

                          <li
                            class="mt-5"
                            v-for="(
                              itemfeatures, index
                            ) in approvedchild_details.product_data
                              .key_features"
                            :key="index"
                          >
                            {{ itemfeatures }}
                          </li>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        Color :

                        {{ approvedchild_details.color }}
                      </v-col>
                      <v-col cols="6" class="pa-5">
                        Size :

                        {{ approvedchild_details.size }}
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card>
            </v-card>

            <!-- <v-card class="pa-5">
              <v-card-text class="pt-0" style="background: #f5f5f5">
                <v-card-title class="py-2 px-0 font-weight-bold"
                  >Child Site Shared Product Price</v-card-title
                >
                <v-divider></v-divider>
                <v-simple-table fixed-header>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">Quantity</th>
                        <th class="text-center">Status</th>
                        <th class="text-center">Purchase Price</th>
                      </tr>
                    </thead>
                    <tbody align="center">
                      <tr
                        v-for="(item, index) in approvedchild_details.max_min"
                        :key="index"
                      >
                        <td class="text-center">
                          {{ item.quantity }}
                        </td>
                        <td class="text-center">
                          {{ item.status }}
                        </td>
                        <td class="text-center">
                          {{ item.purchase_price }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card> -->

            <v-card class="pa-5">
              <v-card-text class="pt-0">
                <v-card-title class="py-2 px-0 font-weight-bold">
                  Assigned Price For Shared Product</v-card-title
                >
                <v-divider></v-divider>
                <v-simple-table fixed-header height="250px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Quantity</th>
                        <th class="text-left">Status</th>
                        <th class="text-center">Selling Price</th>
                        <!-- <th class="text-left">Action</th> -->
                      </tr>
                    </thead>
                    <tbody align="center">
                      <tr
                        v-for="(item, index) in approvedchild_details.max_min"
                        :key="index"
                      >
                        <!-- <td class="text-left">
                          <v-form>
                            <v-text-field
                              v-if="index"
                              outlined
                              type="number"
                              style="max-width: 100px; height: 50px"
                              class="mt-2"
                              flat
                              dense
                              v-model="item.quantity"
                            ></v-text-field>
                            <p v-else>1</p>
                          </v-form>
                        </td> -->
                        <td class="text-left">{{ item.quantity }}</td>
                        <td class="text-left">{{ item.status }}</td>
                        <td>{{ item.selling_price }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>

              <v-card-actions>
                <v-row>
                  <v-col cols="5" class="d-flex flex-row">
                    <p
                      v-if="
                        approvedchild_details.child_max_min &&
                        approvedchild_details.child_max_min.length > 0
                      "
                      class="pt-5 px-2 font-weight-medium"
                    >
                      MRP : {{ approvedchild_details.child_max_min[0].mrp }}
                    </p>
                    <!-- <v-text-field
                      outlined
                      type="number"
                      style="max-width: 150px; height: 50px"
                      class="mt-2"
                      flat
                      dense
                      v-model="mrp"
                    ></v-text-field> -->
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" centered :color="color">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    text: "",
    color: "",
    valid_add_price_specification: false,
    snackbar: false,
    search: "",
    districts: [],
    selectedDistrict: "",
    selectedThana: [],
    dialog: false,
    dialogapproved: false,
    orders: [],
    percent_type: ["Percentage", "Amount"],
    contact: {},
    sellerPdetails: {},
    child_details: {
      input_mrp: 0,
      delivery_units: ["kg", "gm", "mg"],
      product_data: {},
      delivery_product_unit: "gm",
      delivery_weight: 0,
      delivery_height: 0,
      delivery_width: 0,
      delivery_length: 0,
      delivery_free: false,
      option: "all",
      arrayForDelivery: [],
      districts: [],
      category_data: {},
      product_code: {},
      child_max_min: [
        {
          status: "",
          quantity: 0,
          purchase_price: 0,
          selling_price: 0,
          MRP: 0,

          increament_type: "",
          increament_value: 0,
        },
        {
          status: "Minimum",
          quantity: 0,
          purchase_price: 0,
          selling_price: 0,
          increament_type: "",
          increament_value: 0,
        },
        {
          status: "Maximum",
          quantity: 0,
          purchase_price: 0,
          selling_price: 0,
          increament_type: "",
          increament_value: 0,
        },
      ],
    },

    approvedchild_details: {
      product_data: {},
      category_data: {},
      product_code: {},
      max_min: [],
    },

    all_shared_product: [],
    approved_product: [],
    pending_product: [],
    cancelled_product: [],
    specprice: [
      {
        quantity: 1,
        status: "Single",
        purchase_price: "",
        selling_price: "",
      },
      {
        quantity: "",
        status: "Minimum",
        purchase_price: "",
        selling_price: "",
      },
      {
        quantity: "",
        status: "Maximum",
        purchase_price: "",
        selling_price: "",
      },
    ],
    approve_headers: [
      {
        text: "Specification ID",
        value: "id",
      },
      { text: "Product Name", value: "product_data.title", sortable: false },
      { text: "Brand", value: "product_data.brand", sortable: false },
      { text: "Color", value: "color", sortable: false },
      { text: "Size", value: "size", sortable: false },
      { text: "Site Name ", value: "site_id.name", sortable: false },
      // { text: "Order Status", value: "order_status", sortable: false },
      // { text: "Admin Status", value: "admin_status", sortable: false },
      // { text: "Delivery Status", value: "delivery_status", sortable: false },
      // { text: "Ref.No", value: "reference_id", sortable: false },
      { text: "Details", value: "actions", sortable: false },
    ],
    headerspending: [
      {
        text: "Specification ID",
        value: "id",
      },
      { text: "Product Name", value: "product_data.title", sortable: false },
      { text: "Brand", value: "product_data.brand", sortable: false },
      { text: "Color", value: "color", sortable: false },
      { text: "Size", value: "size", sortable: false },
      { text: "Site Name ", value: "site_id.name", sortable: false },
      // { text: "Order Status", value: "order_status", sortable: false },
      // { text: "Admin Status", value: "admin_status", sortable: false },
      // { text: "Delivery Status", value: "delivery_status", sortable: false },
      // { text: "Ref.No", value: "reference_id", sortable: false },
      { text: "Details", value: "actions", sortable: false },
    ],
    cancelled_headers: [
      {
        text: "Specification ID",
        value: "id",
      },
      { text: "Product Name", value: "product_data.title", sortable: false },
      { text: "Brand", value: "product_data.brand", sortable: false },
      { text: "Color", value: "color", sortable: false },
      { text: "Size", value: "size", sortable: false },
      { text: "Site Name ", value: "site_id.name", sortable: false },
      // { text: "Order Status", value: "order_status", sortable: false },
      // { text: "Admin Status", value: "admin_status", sortable: false },
      // { text: "Delivery Status", value: "delivery_status", sortable: false },
      // { text: "Ref.No", value: "reference_id", sortable:
    ],

    loading: true,
  }),

  methods: {
    initialize() {
      // Get all invoices of this admin
      axios.get("productdetails/getunshared_spec/").then((response) => {
        this.sellerPdetails = response.data.data;

        this.loading = false;
      });

      // Get all approved invoices of this admin
      axios
        .get("productdetails/childsite_approved_products/")
        .then((response) => {
          this.approved_product = response.data.data;
        });

      // Get all pending invoices of this admin
      axios
        .get("productdetails/childsite_pending_products/")
        .then((response) => {
          this.pending_product = response.data.data;
        });

      // Get all cancelled Product of this admin
      axios
        .get("productdetails/childsite_cancelled_products/")
        .then((response) => {
          this.cancelled_product = response.data.data;
        });
    },

    // Details of an Approved Product
    viewApprove(item) {
      this.dialogapproved = true;
      Object.assign(this.approvedchild_details, item);

      // let routeData =
      // this.$router.push({
      //   name: "Child Product Details",
      //   params: { child : this.child_details },
      // });
    },

    // Details of an Product
    view(item) {
      this.dialog = true;
      Object.assign(this.child_details, item);
      // console.log("this is child details", this.child_details);

      // let routeData =
      // this.$router.push({
      //   name: "Child Product Details",
      //   params: { child : this.child_details },
      // });
    },
    SaveApprove(child_details) {
      // console.log("this is approved data after confirm", child_details.child_max_min)
      child_details.child_max_min.forEach((element) => {
        element.purchase_price = element.selling_price;
        element.MRP = parseFloat(child_details.input_mrp);
      });

      let datas = {
        max_min: this.child_details.child_max_min,
        arrayForDelivery: this.child_details.arrayForDelivery,
        delivery_free: this.child_details.delivery_free,
        option: this.child_details.option,
        spec: this.child_details.id,
      };
      //console.log("this is fianl array" , datas)
      axios
        .post(
          `productdetails/approve_childsite_product/${this.child_details.id}/`,
          datas
        )
        .then((response) => {
          if (response.data.success) {
            this.pending_product = this.pending_product.filter(
              (contact) => contact.id != child_details.id
            );
            this.dialog = false;
            this.ShareProductmodel = false;
            this.text = response.data.message;
            this.color = "success";
            this.snackbar = true;
            window.location.reload();
          } else {
            this.text = response.data.message;
            this.color = "red";
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.text = "Something went wrong !!";
          //this.text = response.data.message;
          this.color = "error";
          this.snackbar = true;
        });
    },

    // demo() {
    //   axios.get("product/unpublished_products/").then((response) => {
    //     this.orders = response.data.data;

    //     this.loading = false;
    //   });
    // },
    closedialog() {
      this.dialog = false;
    },
    CancelApprove(child_details) {
      // console.log("this is cancelled product array", child_details);
      axios
        .get(`productdetails/cancel_childsite_product/${child_details.id}/`)
        .post(
          `productdetails/cancel_childsite_product/${this.child_details.id}/`
        )
        .then((response) => {
          this.pending_product = this.pending_product.filter(
            (contact) => contact.id != child_details.id
          );
          this.dialog = false;
          this.text = response.data.message;
          this.text = " Product Approval Cancell successfully !";
          this.color = "red";
          this.snackbar = true;
        });
    },

    // If delivery option changed
    deliveryOptionChanged(deliveryOption) {
      this.child_details.arrayForDelivery.length = 0;

      // Create an object for manual location
      if (deliveryOption != "all") {
        this.child_details.arrayForDelivery.push({
          selectedDistrict: "",
          selectedThana: [],
          thanas: [],
          active: false,
        });
      }
    },

    // Increase array for delivery locations
    increaseDeliveryLength(index) {
      this.child_details.arrayForDelivery[index].active = true;
      if (
        this.child_details.districts.length ==
        this.child_details.arrayForDelivery.length
      ) {
        this.text = "Please delete an district first";
        this.color = "warning";
        this.snackbar = true;
      } else {
        this.child_details.arrayForDelivery.push({
          selectedDistrict: "",
          selectedThana: [],
          thanas: [],
          active: false,
        });
      }
    },

    // Decrease array for delivery locations
    deleteDeliveryLength(index) {
      this.child_details.arrayForDelivery.splice(index, 1);
    },

    // get thanas if user select an District
    onSelectDistrict(delivery, index) {
      // this.districtsArrayForCalculation = this.districtsArrayForCalculation.filter(
      //   (element) =>
      //     element.Area_name !=
      //     this.addItem.arrayForDelivery[
      //       this.addItem.arrayForDelivery.length - 1
      //     ].selectedDistrict
      // );

      // Make API call to fetch available thana under selected district

      axios
        .get(`supports/getlocation/${delivery.selectedDistrict}`)
        .then((response) => {
          this.child_details.arrayForDelivery[index].thanas =
            response.data.data;
          // console.log("this is thana array length", response.data.data);
        });
    },

    // Assign item for edit
    assignEditItem(item) {
      this.editedIndex = this.contract.indexOf(item);

      if (item.delivery_location.length) {
        item.option = "manual";
      } else {
        item.option = "all";
      }

      this.editedItem = Object.assign({}, item);

      this.EditArrayForDelivery = JSON.parse(
        JSON.stringify(this.editedItem.delivery_location)
      );

      this.EditArrayForDelivery.forEach((element) => {
        element.active = false;
      });

      this.dialog = true;
    },

    // If delivery option changed
    deliveryOptionChangedOnedit(deliveryOption) {
      // this.EditArrayForDelivery = [];

      // // Create an object for manual location
      if (deliveryOption != "all" && !this.EditArrayForDelivery.length) {
        this.EditArrayForDelivery.push({
          selectedDistrict: "",
          selectedThana: [],
          thanas: [],
          active: false,
        });
      }
    },

    increaseDeliveryLengthOnedit(index) {
      // console.log("I am called ");
      this.EditArrayForDelivery.push({
        selectedDistrict: "",
        selectedThana: [],
        thanas: [],
      });
    },

    deleteDeliveryLengthOnedit(index) {
      this.EditArrayForDelivery.splice(index, 1);
    },

    onSelectDistrictOnEdit(delivery, index) {
      axios
        .get(`supports/getlocation/${delivery.selectedDistrict}`)
        .then((response) => {
          this.EditArrayForDelivery[index].thanas = response.data.data;
        });
    },
  },

  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.initialize();
    //this.demo();
    // Fetch all available Districts
    axios.get(`supports/all_areas/`).then((response) => {
      if (response.data.success) {
        this.child_details.districts = response.data.data;
        // keep refernce
        this.districtsArrayForCalculation = response.data.data;

        //  this.districts = [
        //    {
        //      Area_name : "Dhaka"
        //    },
        //    {
        //      Area_name : "B"
        //    },
        //    {
        //      Area_name : "C"
        //    }
        //  ];
        // // keep refernce
        // this.districtsArrayForCalculation = this.districts
      }
    });
  },
};
</script>


<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>




